export const MenuItems = [
  {
    title: "About TedxPDEU",
    path: "/marketing",
    cName: "dropdown-link",
  },
  {
    title: "About Ted",
    path: "/consulting",
    cName: "dropdown-link",
  },
  {
    title: "About Tedx",
    path: "/design",
    cName: "dropdown-link",
  },
];
